import React from "react"
import Layout from "../components/Layout"
import { StaticImage } from "gatsby-plugin-image"
import { GalleryStyles, Container } from "../components/Gallery/GalleryStyles"
import Seo from "../components/SEO"
import ImageTextModule from "../components/ImageTextModule/ImageTextModule"

const Gallery = () => {
  return (
    <>
      <Seo title="Food" />
      <Layout>
        <Container>
          {/* <h1>
            Menu pictures coming soon! for now please take a look at our menu
            <span style={{ color: "var(--primary)" }}>.</span>
          </h1> */}
          <ImageTextModule
            title="Food gallery coming soon!"
            image="https://images.unsplash.com/photo-1585032226651-759b368d7246?q=80&w=1892&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            menu
            content="
          Pictures of our delicious food will be available soon, feel free to check out our menu for now"
          />
          {/* <GalleryStyles>
            <span>
              <StaticImage
                className="image"
                src="https://i.imgur.com/jZs6Yx3.jpg"
                alt="bubble-tea/dessert"
                placeholder="blurred"
                imgStyle={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "20px",
                }}
              />
            </span>
            <span>
              <StaticImage
                className="image"
                src="https://i.imgur.com/Mp2We86.jpg"
                alt="bubble-tea/dessert"
                placeholder="blurred"
                imgStyle={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "20px",
                }}
              />
            </span>
            <span>
              <StaticImage
                className="image"
                src="https://i.imgur.com/CDmRe4D.jpg"
                alt="bubble-tea/dessert"
                placeholder="blurred"
                imgStyle={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "20px",
                }}
              />
            </span>
            <span>
              <StaticImage
                className="image"
                src="https://i.imgur.com/s4TpG1T.jpg"
                alt="bubble-tea/dessert"
                placeholder="blurred"
                imgStyle={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "20px",
                }}
              />
            </span>
            <span>
              <StaticImage
                className="image"
                src="https://i.imgur.com/dazqmga.jpg"
                alt="bubble-tea/dessert"
                placeholder="blurred"
                imgStyle={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "20px",
                }}
              />
            </span>
            <span>
              <StaticImage
                className="image"
                src="https://i.imgur.com/wtxMOjG.jpg"
                alt="bubble-tea/dessert"
                placeholder="blurred"
                imgStyle={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "20px",
                }}
              />
            </span>
            <span>
              <StaticImage
                className="image"
                src="https://i.imgur.com/Lg0zJM9.jpg"
                alt="bubble-tea/dessert"
                placeholder="blurred"
                imgStyle={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "20px",
                }}
              />
            </span>
            <span>
              <StaticImage
                className="image"
                src="https://i.imgur.com/u53mZjl.jpg"
                alt="bubble-tea/dessert"
                placeholder="blurred"
                imgStyle={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "20px",
                }}
              />
            </span>
            <span>
              <StaticImage
                className="image"
                src="https://i.imgur.com/q8U7Nla.jpg"
                alt="bubble-tea/dessert"
                placeholder="blurred"
                imgStyle={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "20px",
                }}
              />
            </span>
            <span>
              <StaticImage
                className="image"
                src="https://i.imgur.com/2ro6mLF.jpg"
                alt="bubble-tea/dessert"
                placeholder="blurred"
                imgStyle={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "20px",
                }}
              />
            </span>
            <span>
              <StaticImage
                className="image"
                src="https://i.imgur.com/ZcQXlP7.jpg"
                alt="bubble-tea/dessert"
                placeholder="blurred"
                imgStyle={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "20px",
                }}
              />
            </span>
            <span>
              <StaticImage
                className="image"
                src="https://i.imgur.com/6SrEuG2.jpg"
                alt="bubble-tea/dessert"
                placeholder="blurred"
                imgStyle={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "20px",
                }}
              />
            </span>
            <span>
              <StaticImage
                className="image"
                src="https://i.imgur.com/Y8SQXGE.jpg"
                alt="bubble-tea/dessert"
                placeholder="blurred"
                imgStyle={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "20px",
                }}
              />
            </span>
            <span>
              <StaticImage
                className="image"
                src="https://i.imgur.com/y8Lo163.jpg"
                alt="bubble-tea/dessert"
                placeholder="blurred"
                imgStyle={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "20px",
                }}
              />
            </span>
            <span>
              <StaticImage
                className="image"
                src="https://i.imgur.com/BEDTULd.jpg"
                alt="bubble-tea/dessert"
                placeholder="blurred"
                imgStyle={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "20px",
                }}
              />
            </span>
            <span>
              <StaticImage
                className="image"
                src="https://i.imgur.com/r2DO8Zd.jpg"
                alt="bubble-tea/dessert"
                placeholder="blurred"
                imgStyle={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "20px",
                }}
              />
            </span>
            <span>
              <StaticImage
                className="image"
                src="https://i.imgur.com/zNK5JGa.jpg"
                alt="bubble-tea/dessert"
                placeholder="blurred"
                imgStyle={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "20px",
                }}
              />
            </span>
            <span>
              <StaticImage
                className="image"
                src="https://i.imgur.com/kgNaROc.jpg"
                alt="bubble-tea/dessert"
                placeholder="blurred"
                imgStyle={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "20px",
                }}
              />
            </span>
            <span>
              <StaticImage
                className="image"
                src="https://i.imgur.com/7b5XkaD.jpg"
                alt="bubble-tea/dessert"
                placeholder="blurred"
                imgStyle={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "20px",
                }}
              />
            </span>
            <span>
              <StaticImage
                className="image"
                src="https://i.imgur.com/inFR7lM.jpg"
                alt="bubble-tea/dessert"
                placeholder="blurred"
                imgStyle={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "20px",
                }}
              />
            </span>
            <span>
              <StaticImage
                className="image"
                src="https://i.imgur.com/FTpAS73.jpg"
                alt="bubble-tea/dessert"
                placeholder="blurred"
                imgStyle={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "20px",
                }}
              />
            </span>
            <span>
              <StaticImage
                className="image"
                src="https://i.imgur.com/5MCzTFO.jpg"
                alt="bubble-tea/dessert"
                placeholder="blurred"
                imgStyle={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "20px",
                }}
              />
            </span>
            <span>
              <StaticImage
                className="image"
                src="https://i.imgur.com/hIDRTwD.jpg"
                alt="bubble-tea/dessert"
                placeholder="blurred"
                imgStyle={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "20px",
                }}
              />
            </span>
            <span>
              <StaticImage
                className="image"
                src="https://i.imgur.com/IonGWlX.jpg"
                alt="bubble-tea/dessert"
                placeholder="blurred"
                imgStyle={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "20px",
                }}
              />
            </span>
            <span>
              <StaticImage
                className="image"
                src="https://i.imgur.com/AnsfVf1.jpg"
                alt="bubble-tea/dessert"
                placeholder="blurred"
                imgStyle={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "20px",
                }}
              />
            </span>
            <span>
              <StaticImage
                className="image"
                src="https://i.imgur.com/xzI9Bll.jpg"
                alt="bubble-tea/dessert"
                placeholder="blurred"
                imgStyle={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "20px",
                }}
              />
            </span>
            <span>
              <StaticImage
                className="image"
                src="https://i.imgur.com/7sFpSYH.jpg"
                alt="bubble-tea/dessert"
                placeholder="blurred"
                imgStyle={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "20px",
                }}
              />
            </span>
            <span>
              <StaticImage
                className="image"
                src="https://i.imgur.com/KZDdYco.jpg"
                alt="bubble-tea/dessert"
                placeholder="blurred"
                imgStyle={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "20px",
                }}
              />
            </span>
            <span>
              <StaticImage
                className="image"
                src="https://i.imgur.com/zB4ZoEU.jpg"
                alt="bubble-tea/dessert"
                placeholder="blurred"
                imgStyle={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "20px",
                }}
              />
            </span>
          </GalleryStyles> */}
        </Container>
      </Layout>
    </>
  )
}

export default Gallery
